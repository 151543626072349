<template>
	<div class="flex justify-center">
		<q-card
			:class="$q.screen.gt.xs ? 'q-pa-md q-mt-xl' : 'q-mt-xl'"
			:style="$q.screen.gt.xs ? 'width: 700px; max-width: 80vw; height: fit-content' : 'width: 100%; height: fit-content'"
		>
			<span class="text-h5 flex justify-center q-mt-md">Enter new Password</span>
			<q-card-section class="text-center">
				<q-input
					counter
					:hint='show_password_info ? password_complexity_text : "Please remember all fields are case sensitive"'
					outlined
					class="q-mt-lg"
					label="Password"
					bottom-slots
					v-model="password"
					type="password"
					:error="password_error"
				>
					<template v-slot:error v-slot-scoped:errorMessage='{password_error_message}'>
						<p class="q-mb-xs" v-for="message in password_error_message.split('\n')" :key="message">
							{{message}}
						</p>
					</template>
				</q-input>
				<q-input
					counter
					:hint='show_password_info ? password_complexity_text : "Please remember all fields are case sensitive"'
					outlined
					class="q-mt-lg"
					label="Re-enter password"
					bottom-slots
					v-model="confirm_password"
					type="password"
					:error="confirm_password_error"
				>
					<template v-slot:error v-slot-scoped:errorMessage='{confirm_password_error_message}'>
						<p class="q-mb-xs" v-for="message in password_error_message.split('\n')" :key="message">
							{{message}}
						</p>
					</template>
				</q-input>
				<q-card-actions style="justify-content: center; width: 100%" class="q-mt-xl">
					<q-btn
						push
						color='primary'
						background-color='primary'
						class="login-btn full-width"
						@click="setNewPassword"
						label="SET PASSWORD"
						:disabled="!password || !confirm_password"
					/>
				</q-card-actions>
			</q-card-section>
		</q-card>
	</div>
</template>
<script>
import Recaptcha  from '@/components/login/LoginRecaptcha.vue';
import LoginAPI from '@/services/api/Login.js';
import DetailsButton from '@/components/datasets/edit/settings/DetailsButton.vue'

export default ({
	name: 'SetPassword',
	meta: {
		title: 'Alert Cascade - Set Password'
	},
	components: {
		Recaptcha,
		LoginAPI,
		DetailsButton
	},
	data(){
		return{
			password: '',
			confirm_password: '',
			token: '',
			uid: '',
			password_error: false,
			confirm_password_error: false,
			password_error_message: '',
			confirm_password_error_message: '',
			complexity_data: [],
			password_complexity_text: 'The password must contain at least:\n',
			complexity_values: {
				'0': '',
				'1': 'one lowercase character, one uppercase character',
				'2': 'one lowercase character, one uppercase character, one number',
				'3': 'one lowercase character, one uppercase character, one number, one special character'
			},
			show_password_info: false
		}
	},
	methods:{
		async checkEmailVerification(){
			if (this.$route.query.verify_email_token){
				let email_token = this.$route.query.verify_email_token
				let response = await LoginAPI.verifyEmail({'key': email_token})
				if(response.data == "already_verified" || response.data == "Your email was successfully verified.") {
					let response_complexity = await LoginAPI.passwordComplexity(email_token)
					this.show_password_info = true
					this.complexity_data = response_complexity.data
					this.password_complexity_text += this.complexity_data.min_length + ' charcters\n'
					this.password_complexity_text += this.complexity_values[this.complexity_data.complexity]
					return
				}
				this.showErrorNotification(response.data)
			}
		},
		showErrorNotification(message){
			this.$q.notify({
				timeout: 6700,
				message: message,
				actions: [
					{
						label: 'Dismiss',
						color: 'primary',
					},
				],
			})
		},
		async setNewPassword(){
			this.confirm_password_error = this.password_error = false
			this.confirm_password_error_message = this.password_error_message = ''
			const data = {
				'uid': this.uid,
				'token': this.token,
				'new_password1': this.password,
				'new_password2': this.confirm_password,
			}
			let response = await LoginAPI.resetPasswordConfirm(data)
			if(response.error){
				if(response.data.token != undefined){
					this.showErrorNotification("The token has expired. Please request the reset password again.")
					setTimeout(() => {
						this.$router.push({ name: "Login User" })
					}, 6700);
					return
				}
				this.createErrorMessage(response.data)
				return
			}
			this.$q.notify(response.data)
			setTimeout(() => {
				this.$router.push({ name: "Login User" })
			}, 6700);
		},
		createErrorMessage(errors){
			this.confirm_password_error = this.password_error = true
			this.confirm_password_error_message = this.password_error_message
			for (let message of Object.values(errors)){
				for (let error_message of message){
					this.confirm_password_error_message = this.password_error_message += error_message + '\n'
				}
			}
		}
	},
	async created() {
		this.token = this.$route.params.token
		this.uid = this.$route.params.uid
		await this.checkEmailVerification()
	},
	watch:{
		password: function(){
			if (!this.password.length){
				this.password_error = false
				this.password_error_message = ''
			}
		},
		confirm_password: function(){
			if(this.confirm_password.length){
				this.confirm_password_error = false
				this.confirm_password_error_message = ''
			}
		},
	}
})
</script>
<style>
	.details{
		white-space: pre-line;
	}
</style>
