<template>
	<div class="btn-container q-my-sm">
		<button
			v-if="tooltip_content"
			:class="btn_class_list"
			@click="toggleContent"
		>
			INFO
			<span class="btn-border-overlay"></span>
		</button>
		<div
			:id="`${id}`"
			:class="class_list"
		>
			<div class="flex column q-pa-md">
				<span style="font-size: 14px;">
					{{ tooltip_content }}
				</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'DetailsButton',
	props: {
		tooltip_content: String,
		name: String
	},
	data() {
		return {
			display_content_explanation: false,
			guid: null
		}
	},
	computed: {
		id() {
			return this.getId()
		},
		class_list() {
			return {
				'content-explanation': true,
				'inline-block': true,
			}
		},
		btn_class_list() {
			return {
				'details-btn': true,
				'active-details-btn': this.display_content_explanation,
			}
		},
	},
	methods: {
		getId() {
			return `${this.name}-${this.guid}`
		},
		toggleContent() {
			let element = document.querySelector(`#${this.getId()}`)
			let height = element.scrollHeight
			if (!this.display_content_explanation) {
				element.style.maxHeight = height + 'px'
				element.style.opacity = 1
			} else {
				element.style.maxHeight = 0 + 'px'
				element.style.opacity = 0
			}
			this.display_content_explanation = !this.display_content_explanation
		},
	},
	created() {
		this.guid = Math.random().toString(16).slice(2)
	},
}
</script>

<style lang="scss" scoped>
.btn-border-overlay {
	position: absolute;
	width: 100%;
	left: 0px;
	opacity: 0;
	transition: all 300ms ease-in-out;
	height: 1px;
	bottom: -1px;
	z-index: 3;
	background: var(--ac-highlight-color);
}
.details-btn {
	font-size: 0.75rem;
	line-height: 1rem;
	font-weight: 600;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	margin-right: auto;
	position: relative;
	background: #fff;
	border: 1px solid $grey-3;
	cursor: pointer;
	&:hover {
		background: $grey-2;
	}
	color: var(--ac-highlight-color);
	&::before,
	&::after {
		width: 100%;
		left: 0px;
		position: absolute;
		display: block;
		transition: all 300ms ease-in-out;
		opacity: 0;
		content: '';
	}
}
.active-details-btn {
	&:hover {
		background: transparent;
	}
	border-bottom-width: 0px;
	&::before {
		height: 100%;
		bottom: 0px;
		opacity: 0.1;
		background-color: var(--ac-highlight-color);
	}
	&:hover {
		&::before {
			opacity: 0.2;
		}
	}
	&::after {
		background: #fff;
		opacity: 1;
		height: 1px;
		bottom: -1px;
		z-index: 2;
	}
	& .btn-border-overlay {
		opacity: 0.1;
	}
}
.content-explanation {
	text-align: left;
	font-size: 14px;
	line-height: 1rem;
	line-height: 1rem;
	transition: all 300ms ease-in-out;
	opacity: 0;
	overflow: hidden;
	max-height: 0px;
	position: relative;
	width: 100%;
	border-width: 1px;
	border-color: $grey-3;
	&::before {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		bottom: 0;
		opacity: 0.1;
		content: '';
		background-color: var(--ac-highlight-color);
	}
	color: var(--ac-highlight-color);
}

.btn-container {
	display: flex;
	flex-direction: column;
}

</style>
